import React from "react"
import styles from "./obuchenie-po-crm.module.scss"

import PresentationOverlayService from "../../../../../services/presentation-overlay-service"

const HeroObucheniePoCrm = ({ title, description }) => (
  <div className={styles.hero}>

    <div className={`container ${styles.container}`}>
      <h1 className={styles.heading}>
        {title}
      </h1>
      <p className={styles.subheading}>{description}</p>

      <button className={styles.cta} onClick={() => PresentationOverlayService.open()}>
        Оставить заявку
      </button>
    </div>

  </div>
)

export default HeroObucheniePoCrm
