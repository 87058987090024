import React from "react"
import { openOverlay } from "../../../../utils/overlay"
import styles from "./education-banner.module.scss"


const HeroBannerObucheniePoCrm = ({ title, name }) => (
  <div className={styles.hero}>
    <div className={`container ${styles.container}`}>

      <h1 className={styles.heading}> {title} </h1>

      <button className={styles.cta} onClick={() => openOverlay(name, "hero", "trial")}>
        Начать бесплатно
      </button>

    </div>
  </div>
)

export default HeroBannerObucheniePoCrm
