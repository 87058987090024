import React from "react"
import SEO from "../components/seo"
import Layout from "../components/layout"

import HeroObucheniePoCrm from "../components/pages/shared/hero/obuchenie-po-crm"
import Breadcrumb from '../components/controls/breadcrumbs';
import Support from "../components/pages/partners/support"
import HeroBannerObucheniePoCrm from "../components/pages/obuchenie/education-banner"
import EducationPageImfo from "../components/pages/obuchenie/education-info"
import EducationPageHead from "../components/pages/obuchenie/education-head"
import ContactFormComponent from "../components/pages/shared/contact-form"

const FranchisePage = (props) => {
    const crumbs = [
        { name: 'Главная', url: '/' },
        { name: 'Обучение по CRM', url: '' }
    ];
    const supportlist = [
        { icon: require("../images/partners/support/tool.svg"), descr: 'Предложим готовые решения проблем' },
        { icon: require("../images/partners/support/partnership.svg"), descr: 'Поможем организовать работу в CRM под ваш бизнес' },
        { icon: require("../images/partners/support/support.svg"), descr: 'Ответим на любые вопросы по внедрению CRM' },
        { icon: require("../images/partners/support/settings.svg"), descr: 'Настроим интеграции вместе с вами ' },
        { icon: require("../images/partners/support/pay.svg"), descr: 'Продемонстрируем возможности системы' },
        { icon: require("../images/partners/support/trending-up.svg"), descr: 'Покажем способы увеличения эффективности' },
    ];
    return (
        <Layout headerColor={"#6D20D2"} page={"franchise"}>

            <SEO title="Обучение по CRM | CRM-система учета для детских центров"
                description="Пройдите персональное обучение по системе с менеджером Параплан"
                keywords={[`Параплан обучение`, `разработка CRM-системы`]} url={"https://paraplancrm.ru" + props.location.pathname}
            />
            
            <HeroObucheniePoCrm
                name={"franchise"}
                title='Ускорьте внедрение CRM'
                description='Пройдите персональное обучение по системе с менеджером Параплан'
                color='#03C4A4'
            />
            <Breadcrumb crumbs={crumbs} arrowcolor={"purple"} />

            <EducationPageHead title='Обучение по работе в Параплан CRM'
                subtitle='Один час онлайн с менеджером убережет от ошибок и поможет с нуля вести систему максимально корректно.' />

            <Support supportlist={supportlist} title='Обучение по CRM - самый быстрый способ освоения системы' slogan='Вы не останетесь с CRM один на один!' />

            <HeroBannerObucheniePoCrm title='Легко наведите порядок  в сложных процессах' />

            <EducationPageImfo
                title='Стоимость и условия обучения по CRM'
                prompt='При покупке за пределами РФ может включать дополнительные налоги страны лицензиата.'
                subtitle='Для клиентов на тестовом доступе системы обучение бесплатное и без ограничений. Для пользователей после первой оплаты подписки действуют условия из <a href="/documentation" target="_blank">Оферты</a>.' />
            <ContactFormComponent />
        </Layout>
    )
}

export default FranchisePage
