import React from "react"
import styles from "./education-info.module.scss"

const EducationPageImfo = ({ title, subtitle, prompt }) => (
    <div className={styles.info}>
        <div className={styles.head}>
            <h2 className={styles.title}>{title}</h2>
            <p className={styles.subtitle} dangerouslySetInnerHTML={{ __html: subtitle }}></p>
        </div>
        <div className={styles.blocks}>
            <div className={styles.left}>
                <img className={styles.icon} src={require("../../../../images/icons/globe.svg")} alt="" />
                <div className={styles.text}>
                    Обучение проводится менеджером Параплана только онлайн.
                </div>
            </div>
            <div className={styles.right}>
                <div className={styles.wrap}>
                    <div className={styles.title}>до 3-х</div>
                    <div className={styles.text}>человек на одной сессии</div>
                </div>
                <div className={styles.wrap}>
                    <div className={styles.title}>100%</div>
                    <div className={styles.text}>предоплата за обучение</div>
                </div>
                <div className={styles.wrap}>
                    <div className={styles.title}>60 мин</div>
                    <div className={styles.text}>стандартная консультация</div>
                </div>
                <div className={styles.wrap}>
                    <div className={styles.title}>1 500 ₽</div>
                    <div className={styles.text}>= 1 час обучения</div>
                </div>
            </div>
        </div>
        <div className={styles.prompt}>{prompt}</div>
    </div>
)

export default EducationPageImfo