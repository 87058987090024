import React from "react"
import styles from "./support.module.scss"

const PartnersSupport = ({ supportlist, slogan, title }) => (
  <div className={styles.support}>
    <div className="container">
      <div className={styles.wrap}>
        <div className={styles.top}>
          {title ? <h2 className={styles.title}>{title}</h2> : <h2 className={styles.title}>Наши партнеры в надежных руках</h2>}
        </div>
        <div className={styles.itemswrap}>
          {supportlist.map(t =>
            <div className={styles.item}>
              <img alt={""} className={styles.icon}
                src={t.icon} />
              <div className={styles.txt}>
                {t.descr}
              </div>
            </div>
          )}
        </div>
        {slogan ? <div className={styles.slogan}> {slogan} </div> :
          <div className={styles.slogan}>
            Начните получать дополнительный доход на стабильно развивающемся рынке!
          </div>}
      </div>
    </div>
  </div>
)

export default PartnersSupport