import React from "react"
import styles from "./education-head.module.scss"

const EducationPageHead = ({ title, subtitle }) => (
    <div className={styles.info}>
        <div className={styles.head}>
            <h2 className={styles.title}>{title}</h2>
            <p className={styles.subtitle} dangerouslySetInnerHTML={{ __html: subtitle }}></p>
        </div>
        <div className={styles.blocks}>
            <div className={`${styles.wrap} ${styles.purple}`}>
                <div className={styles.title}>Общая презентация системы</div>
                <div className={styles.text}>Менеджер Параплана ведет обзор и демонстрацию возможностей CRM для детского центра по заполненной версии системы.</div>
            </div>
            <div className={`${styles.wrap} ${styles.blue}`}>
                <div className={styles.title}>Разбор конкретных вопросов</div>
                <div className={styles.text}>Разбор конкретных вопросов клиента сразу в его аккаунте Параплан CRM. Помощь в решении определенных проблем пользователя.</div>
            </div>
            <div className={`${styles.wrap} ${styles.green}`}>
                <div className={styles.title}>Настройка интеграций</div>
                <div className={styles.text}>Совместная настройка интеграций менеджером Параплана и клиентом во время онлайн встречи. Кроме финансовых интеграций.</div>
            </div>
        </div>
    </div>
)

export default EducationPageHead